jQuery(function ($) {
  // When the custom add-to-cart button is clicked
  $('body').on('click', '.custom-add-to-cart-btn', function (e) {
    e.preventDefault();

    var productID = $(this).data('product_id'); // Get product ID from the button

    // AJAX request to add to cart
    $.ajax({
      url: custom_add_to_cart_params.ajax_url, // The AJAX URL
      type: 'POST',
      data: {
        action: 'custom_add_to_cart', // Custom action name
        product_id: productID, // Pass the product ID
      },
      success: function (response) {
        if (response.success) {
          // Update WooCommerce notices area
          $.ajax({
            url: custom_add_to_cart_params.ajax_url,
            type: 'POST',
            data: {
              action: 'get_woocommerce_notices', // Custom action to fetch updated notices
            },
            success: function (notices) {
              var $noticesWrapper = $('.woocommerce-notices-wrapper');
              $noticesWrapper.html(notices);

              // Show the notice with the 'show' class
              $noticesWrapper.addClass('show');

              // Auto-hide the notice after 5 seconds (adjust as needed)
              // setTimeout(function () {
              //   $noticesWrapper.removeClass('show');
              // }, 5000); // 5000ms = 5 seconds
            }
          });

          // Refresh WooCommerce cart fragments (for mini cart, etc.)
          $(document.body).trigger('wc_fragment_refresh');
        }
      },
      error: function () {
        // Handle request errors (optional)
      }
    });
  });

});
